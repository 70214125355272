<template>
  <div>
    <div class="mt-2">
      <a-tabs type="card" class="w-full" @change="changeTabs" :activeKey="activeTab">
        <a-tab-pane :key="0" tab="未来5天待交付产品"></a-tab-pane>
      </a-tabs>
    </div>

    <template v-if1="activeTab==1">
			<div class="mt-6"></div>
			<base-table
				:columnsData="columns_daily"
				:tableData="tableData_daily"
			></base-table>
    </template>

  </div>
</template>

<script>
import { skuDailyDeliver } from "@/api/shop.js"
export default {
  components: {  },
  data() {
    return {
			activeTab: 0,
			search_date: [],

			tableData_citysales: [],
			columns_citysales: [],

			tableData_daily: [],
			columns_daily: [],

			columns_base: [
				{
					title: "产品",
					dataIndex: "goods_name",
					align: "center",
				},
				{
					title: "口味",
					dataIndex: "flavor_name",
					align: "center",
				},
				{
					title: "尺寸",
					dataIndex: "specification_name",
					align: "center",
				},
			],

    }
  },
  mounted() {
    this.search_date = [
			this.getToday(),
			this.getToday(),
		]

		this.changeTabs(this.activeTab)
  },
  methods: {
    changeTabs(v){
      this.activeTab = v

      if(this.activeTab == 0){
				this.getSkuDailyDeliver()
      }
    },

    async getSkuDailyDeliver() {
			const params = {
				time_start: this.getToday(-1) + '00:00:00',
				time_end:   this.getToday(4) + '23:59:59'
			}
			const { data, code } = await skuDailyDeliver(params)
			if(code != 0) return

			let dateList = data.list[0] && data.list[0].daily_deliver_list
			let columns = []
			let totalItem = {
				goods_name: "总计"
			}
			dateList && dateList.map((day, index)=>{
				columns.push({
					title: index == 0 ? "昨天" : ( index == 1 ? "今天" : day.date ),
					dataIndex: "date_"+index,
					align: "center",
				})
				totalItem["date_"+index] = 0
				data.list.map(el=>{
					let sku_day_num = el.daily_deliver_list[index].number
					totalItem["date_"+index] += sku_day_num
					el["date_"+index] = sku_day_num
					return el
				})

			})
			this.columns_daily = this.columns_base.concat(columns)
			this.tableData_daily = [totalItem].concat(data.list)
    },

		// -----------
		getToday(n){
			n = n || 0
			let day = new Date()
			if(n){
				day.setTime(day.getTime() + n*24*3600*1000)
			}
			return this.formatDate(day)
		},

		getLastMonthFirstDay(){
			const today = new Date()
			let month = today.getMonth()
			let year = today.getFullYear()
			if(month == 0){
				month = 11
				year = year-1
			}else{
				month = month-1
			}
			return this.formatDate(new Date(year, month, 1))
		},
		getLastMonthLastDay(){
			let date = new Date()
			date.setTime(date.getTime() - date.getDate()*24*3600*1000)

			return this.formatDate(date)
		},

    formatDate(date){
			let year = date.getFullYear()
			let month = date.getMonth()
			let day = date.getDate()
			month = month+1
			if(month < 10){
				month = "0"+month
			}
			if(day < 10){
				day = "0"+day
			}
			return year+"-"+month+"-"+day
    },

  },
}
</script>

<style>
</style>